.gurujis-life-content {
  display: grid;
  gap: 20px;

  &:not(:last-of-type) {
    margin-bottom: 50px;
  }

  @include atSmall {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
  @include atSmallAlt {
    grid-template-columns: max-content 1fr;
    gap: 50px;
  }
}

.gurujis-life-content__text {
  @include atMedium {
    text-align: justify;
  }

  &__heading {
    margin-bottom: 15px;
  }
}
