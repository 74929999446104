/*-HTML5 Reset :: style.css---------------------------------------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

article, aside, figure, footer, header, nav, section, details, summary {
  display: block;
}

/* Handle box-sizing while better addressing child elements:
   http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

img,
object,
embed {
  max-width: 100%;
}

img { /*display:block;*/
  vertical-align: bottom;
}

/* force a vertical scrollbar to prevent a jumpy page */
/* we use a lot of ULs that aren't bulleted.
	don't forget to restore the bullets within content. */
ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: bold;
  vertical-align: bottom;
}

td {
  font-weight: normal;
  vertical-align: top;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

pre {
  white-space: pre; /* CSS2 */
  white-space: pre-wrap; /* CSS 2.1 */
  white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word; /* IE */
}

input[type=radio] {
  vertical-align: text-bottom;
}

input[type=checkbox] {
  vertical-align: bottom;
}

.ie7 input[type=checkbox] {
  vertical-align: baseline;
}

.ie6 input {
  vertical-align: text-bottom;
}

select, input, textarea {
  font: 99% sans-serif;
}

table {
  font-size: inherit;
  font: 100%;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

td, td img {
  vertical-align: top;
}

/* Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif;
}

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer;
}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0;
}

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible;
}

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic;
}

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/
/* let's clear some floats */
.clearfix:before, .clearfix:after {
  content: " ";
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

li.clearfix {
  display: list-item;
}

/*Hide these rules from IE-mac */
* html .clearfix {
  height: 1%;
}

.clearfix {
  *zoom: 1;
}

/*-End HTML5 Reset :: style.css------------------------------------------------------------------------------------------*/
/* Mixin to create vertical, horizontal or radial gradient */
:root {
  --gutter: 15px;
}

@media (min-width: 63.125em) {
  :root {
    --gutter: 0;
  }
}
html {
  scroll-behavior: smooth;
}

body {
  background: #661700;
  font: normal 100%/1.5 "PT Serif", Helvetica, Arial, serif;
  color: #661700;
  font-weight: 400;
  margin: 0;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.overflow-hidden {
  overflow: hidden;
}

.wrapper {
  margin: 0 auto;
  max-width: 62.5rem;
  width: calc(100% - var(--gutter) * 2);
}

.container {
  margin: 0 auto;
  max-width: 62.5rem;
}

p {
  word-break: break-word;
  word-wrap: break-word;
}

img {
  border: 0;
  height: auto;
  max-width: 100%;
}

a {
  color: #000000;
  transition: all 0.3s ease-in-out;
}

.nav-toggle-button {
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  position: absolute;
  left: 15px;
  top: 15px;
  top: 18px;
  z-index: 10;
  border-radius: 100%;
}
@media (min-width: 63.125em) {
  .nav-toggle-button {
    display: none;
  }
}

.nav-toggle-button span {
  position: relative;
  height: 2px;
  width: 20px;
  background-color: rgba(0, 0, 0, 0.4);
}

.nav-toggle-button span:before,
.nav-toggle-button span:after {
  width: 100%;
  height: 2px;
  display: block;
  content: "";
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
}

.nav-toggle-button span:before {
  top: -7px;
}

.nav-toggle-button span:after {
  top: 7px;
}

body.overflow-hidden .nav-toggle-button span {
  background-color: transparent !important;
}

body.overflow-hidden .nav-toggle-button span:before {
  transform: rotate(-45deg);
  top: 0;
}

body.overflow-hidden .nav-toggle-button span:after {
  transform: rotate(45deg);
  top: 0;
}

.bg-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
  transition: 0.2s all ease;
  opacity: 0;
  visibility: hidden;
}

.bg-overlay.active {
  z-index: 10;
  opacity: 1;
  visibility: visible;
}

.mobile-navigation {
  background-color: #661700;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 20;
  height: 100%;
  max-width: 300px;
  width: 100%;
  overflow-y: scroll;
  left: -100%;
  transition: 0.3s all ease;
}
@media (min-width: 100em) {
  .mobile-navigation {
    left: -100% !important;
  }
}

.mobile-navigation.active {
  left: 0;
}

.mobile-navigation ul {
  display: flex;
  flex-direction: column;
}

.mobile-navigation ul li {
  font-size: 1.125rem;
  font-weight: 400;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.mobile-navigation ul li.current_page_item a,
.mobile-navigation ul li.current-menu-item a {
  color: #ffffff;
}

.mobile-navigation ul li a {
  padding: 10px 15px 10px 15px;
  min-height: 50px;
  display: block;
  color: #f9b81e;
  text-decoration: none;
}

.mobile-navigation ul li.hasSubMenu > a {
  padding: 10px 50px 10px 15px;
}

.site-header {
  background-color: #661700;
}
.site-header .container {
  position: relative;
}

.header-banner {
  background: url("../images/header/JOYGURU-BANNER-1.jpeg") 48% center no-repeat;
  background-size: cover;
  min-height: 443px;
}

.header-text {
  background: #f9b81e;
  background: linear-gradient(180deg, rgb(241, 187, 78) 40%, rgb(249, 184, 30) 100%);
  padding: 30px 0;
}
.header-text__heading {
  text-transform: uppercase;
}
.header-text__heading span {
  display: block;
}
@media (min-width: 33.125em) {
  .header-text__heading span {
    display: inline-block;
  }
}
.header-text__guru-mantra {
  font-size: 0.875rem;
  margin-top: 15px;
  text-align: center;
  text-transform: uppercase;
  color: #661700;
}
@media (min-width: 40em) {
  .header-text__guru-mantra {
    font-size: 1rem;
  }
}

.main-navigation-wrap {
  background-color: #f9b81e;
  border-top: 2px double #661700;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  display: none;
}
@media (min-width: 63.125em) {
  .main-navigation-wrap {
    display: block;
  }
}

.main-navigation {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
}
.main-navigation li {
  position: relative;
  font-size: 1rem;
  font-family: "PT Serif", Helvetica, Arial, serif;
  text-transform: uppercase;
  flex: auto;
}
.main-navigation li.current-menu-item a, .main-navigation li.current_page_item a {
  background: #c96909;
  color: #ffffff;
}
.main-navigation li a {
  padding: 12px 15px 10px;
  text-decoration: none;
  color: #661700;
  display: block;
  font-weight: 700;
}
.main-navigation li a:hover {
  background: #c96909;
  color: #ffffff;
}

.page-section {
  padding: 30px 0;
}
@media (min-width: 63.125em) {
  .page-section {
    padding: 45px 0;
  }
}
@media (min-width: 80.0625em) {
  .page-section {
    padding: 60px 0;
  }
}
.page-section--bg-black {
  background-color: #000000;
}
.page-section--bg-grey {
  background-color: #333333;
}
.page-section--bg-white {
  background-color: #ffffff;
}
.page-section--bg-primary {
  background-color: #661700;
}
.page-section--bg-accent {
  background-color: #f9b81e;
}
.page-section--no-b-padding {
  padding-bottom: 0;
}
.page-section--no-t-padding {
  padding-top: 0;
}
.page-section--no-padding {
  padding: 0;
}
.page-section--hidden {
  overflow: hidden;
}

.page-section p,
.page-section ul {
  font-size: 1rem;
  color: #661700;
}

.page-section p {
  padding-bottom: 15px;
}
.page-section p:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
}

.page-section p a {
  text-decoration: underline;
}
.page-section p a:hover {
  text-decoration: none;
}

.headline {
  font-family: "PT Serif", Helvetica, Arial, serif;
}
.headline--text-black {
  color: #000000;
}
.headline--text-white {
  color: #ffffff;
}
.headline--text-primary {
  color: #661700;
}
.headline--text-accent {
  color: #f9b81e;
}
.headline--light {
  font-weight: 300;
}
.headline--normal {
  font-weight: 400;
}
.headline--medium {
  font-weight: 500;
}
.headline--semi-bold {
  font-weight: 600;
}
.headline--bold {
  font-weight: 700;
}
.headline--black {
  font-weight: 900;
}
.headline--text-left {
  text-align: left;
}
.headline--text-center {
  text-align: center;
}
.headline--text-uppercase {
  text-transform: uppercase;
}

.headline-2xl {
  font-size: 2.875rem;
  line-height: 1.1;
}
@media (min-width: 75em) {
  .headline-2xl {
    font-size: 3.5rem;
  }
}
@media (min-width: 80.0625em) {
  .headline-2xl {
    font-size: 4.5rem;
  }
}
@media (min-width: 90em) {
  .headline-2xl {
    font-size: 6rem;
  }
}

.headline-xl {
  font-size: 2.25rem;
  line-height: 1.1;
}
@media (min-width: 75em) {
  .headline-xl {
    font-size: 2.875rem;
  }
}
@media (min-width: 80.0625em) {
  .headline-xl {
    font-size: 3.5rem;
  }
}
@media (min-width: 90em) {
  .headline-xl {
    font-size: 4.5rem;
  }
}

.headline-l {
  font-size: 2rem;
  line-height: 1.2;
}
@media (min-width: 75em) {
  .headline-l {
    font-size: 2.625rem;
  }
}
@media (min-width: 80.0625em) {
  .headline-l {
    font-size: 3.25rem;
  }
}
@media (min-width: 90em) {
  .headline-l {
    font-size: 3.75rem;
  }
}

.headline-m {
  font-size: 1.625rem;
  line-height: 1.2;
}
@media (min-width: 75em) {
  .headline-m {
    font-size: 2.25rem;
  }
}
@media (min-width: 80.0625em) {
  .headline-m {
    font-size: 2.75rem;
  }
}
@media (min-width: 90em) {
  .headline-m {
    font-size: 3rem;
  }
}

.headline-s {
  font-size: 1.375rem;
  line-height: 1.4;
}
@media (min-width: 75em) {
  .headline-s {
    font-size: 1.5rem;
  }
}
@media (min-width: 80.0625em) {
  .headline-s {
    font-size: 1.625rem;
  }
}
@media (min-width: 90em) {
  .headline-s {
    font-size: 2.25rem;
  }
}

.headline-xs {
  font-size: 1.25rem;
  line-height: 1.5;
}
@media (min-width: 75em) {
  .headline-xs {
    font-size: 1.375rem;
  }
}
@media (min-width: 80.0625em) {
  .headline-xs {
    font-size: 1.5rem;
  }
}
@media (min-width: 90em) {
  .headline-xs {
    font-size: 1.625rem;
  }
}

.headline-xxs {
  font-size: 1rem;
  line-height: 1.5;
}
@media (min-width: 75em) {
  .headline-xxs {
    font-size: 1.125rem;
  }
}
@media (min-width: 80.0625em) {
  .headline-xxs {
    font-size: 1.25rem;
  }
}
@media (min-width: 90em) {
  .headline-xxs {
    font-size: 1.375rem;
  }
}

.subline-text {
  font-size: 0.875rem;
  color: #000000;
  font-weight: 700;
  padding: 8px 0 0 0;
}
.subline-text--text-black {
  font-weight: 900;
}
.subline-text--white {
  color: #ffffff;
}
.subline-text--center {
  text-align: center;
}
@media (min-width: 48em) {
  .subline-text {
    font-size: 1rem;
  }
}
@media (min-width: 63.125em) {
  .subline-text {
    font-size: 1.125rem;
  }
}
@media (min-width: 80.0625em) {
  .subline-text {
    font-size: 1.25rem;
  }
}

.pre-heading {
  font-size: 0.8125rem;
  color: rgba(51, 51, 51, 0.5);
  margin-bottom: 10px;
}
.pre-heading--uppercase {
  text-transform: uppercase;
}
.pre-heading--space-m {
  word-spacing: 5px;
}
.pre-heading--space-s {
  word-spacing: 3px;
}

.text {
  font-size: 1rem;
}

.button-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.button {
  display: inline-block;
  padding: 10px 20px 8px;
  font-size: 1rem;
  transition: all 0.4s ease;
  font-weight: 400;
  text-decoration: none;
}
.button:hover {
  text-decoration: none;
}
.button--text-uppercase {
  text-transform: uppercase;
}
.button--pill {
  border-radius: 100px;
}
.button--rounded-10 {
  border-radius: 10px;
}
.button__icon {
  margin-left: 20px;
}
.button__icon svg {
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
}
.button--grey {
  background-color: #333333;
  border: 1px solid #333333;
  color: #661700;
}
.button--grey:hover {
  background-color: #661700;
  border: 1px solid #661700;
  color: white;
}
.button--primary {
  background-color: #661700;
  border: 1px solid #661700;
  color: #f9b81e;
}
.button--primary:hover {
  background-color: #661700;
  border: 1px solid #661700;
  color: white;
}
.button--primary--hover-white:hover {
  background-color: white;
  border: 1px solid white;
  color: #661700;
}
.button--accent {
  background-color: #f9b81e;
  border: 1px solid #f9b81e;
  color: #661700;
}
.button--accent:hover {
  background-color: #661700;
  border: 1px solid #661700;
  color: white;
}
.button--accent--hover-white:hover {
  background-color: white;
  border: 1px solid white;
  color: #661700;
}
.button--gradient {
  background-color: #f9b81e;
  background: linear-gradient(180deg, rgb(249, 184, 30) 40%, rgb(229, 83, 2) 100%);
  border: 1px solid #f9b81e;
  color: black;
}
.button--gradient:hover {
  background-color: #661700;
  border: 1px solid #661700;
  color: white;
}

.common-page-content {
  padding: 25px;
  border-radius: 10px;
  background-color: #f9b81e;
  background: linear-gradient(180deg, rgb(241, 187, 78) 40%, rgb(249, 184, 30) 100%);
}
@media (min-width: 63.125em) {
  .common-page-content {
    padding: 50px;
  }
}

.home-content {
  display: grid;
  gap: 30px;
}
@media (min-width: 48em) {
  .home-content {
    grid-template-columns: repeat(2, 1fr);
  }
  .home-content__media {
    order: 2;
  }
  .home-content__text {
    order: 1;
  }
}

.home-content__media {
  display: grid;
  gap: 20px;
}
@media (min-width: 48em) {
  .home-content__media {
    align-content: space-between;
  }
}
.home-content__media__images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.home-content__media__images > div img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.home-content__media__button {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 15px;
}

.home-content__text {
  padding: 25px;
  border-radius: 10px;
  background-color: #f9b81e;
  background: linear-gradient(180deg, rgb(241, 187, 78) 40%, rgb(249, 184, 30) 100%);
}
@media (min-width: 63.125em) {
  .home-content__text {
    padding: 50px;
    text-align: justify;
  }
}
.home-content__text__heading {
  padding-bottom: 15px;
}
.home-content__text a.button {
  text-decoration: none !important;
}

.gurujis-life-content {
  display: grid;
  gap: 20px;
}
.gurujis-life-content:not(:last-of-type) {
  margin-bottom: 50px;
}
@media (min-width: 40em) {
  .gurujis-life-content {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
}
@media (min-width: 48em) {
  .gurujis-life-content {
    grid-template-columns: max-content 1fr;
    gap: 50px;
  }
}

@media (min-width: 63.125em) {
  .gurujis-life-content__text {
    text-align: justify;
  }
}
.gurujis-life-content__text__heading {
  margin-bottom: 15px;
}

.mamoni-content:not(:last-of-type) {
  margin-bottom: 50px;
}

@media (min-width: 63.125em) {
  .mamoni-content__text {
    text-align: justify;
  }
}
.mamoni-content__text__heading {
  margin-bottom: 15px;
}

.mamoni-content-two-col {
  display: grid;
  gap: 20px;
}
@media (min-width: 40em) {
  .mamoni-content-two-col {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
}
@media (min-width: 40em) and (min-width: 40em) {
  .mamoni-content-two-col--alt > div:first-child {
    order: 2;
  }
  .mamoni-content-two-col--alt > div:last-child {
    order: 1;
  }
}
@media (min-width: 48em) {
  .mamoni-content-two-col {
    grid-template-columns: max-content 1fr;
    gap: 50px;
  }
  .mamoni-content-two-col--alt {
    grid-template-columns: 1fr max-content;
  }
}

.photos-videos-content:not(:last-of-type) {
  margin-bottom: 50px;
}
.photos-videos-content__heading {
  margin-bottom: 15px;
}
.photos-videos-content__video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
}
.photos-videos-content__video-wrapper iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.photos-videos-content__buttons {
  display: grid;
  gap: 15px;
}
@media (min-width: 40em) {
  .photos-videos-content__buttons {
    grid-template-columns: repeat(3, minmax(max-content, 1fr));
  }
}
.photos-videos-content__buttons .button {
  text-align: center;
  padding: 12px;
}

.donation-content {
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.contact-content {
  display: grid;
  gap: 30px;
  grid-template-areas: "media" "address" "social-donation";
}
@media (min-width: 40em) {
  .contact-content {
    grid-template-areas: "address media" "address social-donation";
  }
}
@media (min-width: 48em) {
  .contact-content {
    column-gap: 50px;
  }
}
.contact-content__media {
  grid-area: media;
}
.contact-content__address {
  grid-area: address;
}
.contact-content__social-donation {
  grid-area: social-donation;
}
.contact-content__text:not(:first-child) {
  padding-top: 30px;
}
.contact-content__text:not(:last-child) {
  padding-bottom: 30px;
  border-bottom: 2px dotted #000000;
}
.contact-content__text__heading {
  margin-bottom: 15px;
}
.contact-content__text .tel {
  padding-bottom: 0;
  text-decoration: none !important;
  border-bottom: none !important;
}
.contact-content__text a:not(.button) {
  color: #661700;
  text-decoration: none !important;
  padding-bottom: 2px;
  border-bottom: 1px solid #000000;
}
.contact-content__text a:not(.button):hover {
  border-bottom: 1px solid transparent;
}
.contact-content__text .button {
  text-decoration: none;
}

/* footer */
.footer-navigation-1-wrap {
  border: 2px dotted #f35c30;
  padding: 15px;
  border-radius: 10px;
}

.footer-navigation-1 {
  display: grid;
  gap: 10px;
}
@media (min-width: 40em) {
  .footer-navigation-1 {
    gap: 20px;
    grid-template-columns: repeat(2, minmax(max-content, 1fr));
  }
}
@media (min-width: 63.125em) {
  .footer-navigation-1 {
    font-size: 1.125rem;
    grid-template-columns: repeat(4, minmax(min-content, max-content));
    justify-content: space-between;
  }
}
.footer-navigation-1 li {
  position: relative;
}
@media (min-width: 63.125em) {
  .footer-navigation-1 li:not(:last-child)::after {
    content: "||";
    position: absolute;
    right: -50px;
    top: 0;
    color: #f35c30;
    display: flex;
  }
}
.footer-navigation-1 a {
  color: #f35c30;
  text-decoration: none;
}
.footer-navigation-1 a:hover {
  color: #ffffff;
}

.footer-navigation-2 {
  display: grid;
  gap: 10px;
  padding: 30px 0;
  border-bottom: 1px solid rgba(243, 92, 48, 0.3);
}
@media (min-width: 40em) {
  .footer-navigation-2 {
    gap: 20px;
    grid-template-columns: repeat(2, minmax(max-content, 1fr));
  }
}
@media (min-width: 63.125em) {
  .footer-navigation-2 {
    row-gap: 10px;
    grid-template-columns: repeat(4, minmax(max-content, 1fr));
  }
}
.footer-navigation-2 a {
  color: rgba(243, 92, 48, 0.6);
  text-decoration: none;
}
.footer-navigation-2 a:hover {
  color: #ffffff;
}

.copyright-credit {
  display: grid;
  gap: 20px;
  padding: 20px 0;
  color: rgba(243, 92, 48, 0.5);
}
@media (min-width: 48em) {
  .copyright-credit {
    grid-template-columns: max-content max-content;
    justify-content: space-between;
  }
}
.copyright-credit a {
  color: rgba(243, 92, 48, 0.5);
}
.copyright-credit a:hover {
  text-decoration: none;
}