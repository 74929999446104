html {
  scroll-behavior: smooth;
}

// ::-webkit-scrollbar {
//   width: 0px;
//   background: transparent;
// }

body {
  background: $brown;
  font: normal 100%/1.5 $body-font;
  color: $primary;
  font-weight: 400;
  margin: 0;
  text-align: left;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body.overflow-hidden {
  overflow: hidden;
}
// .wrapper {
//   width: 100%;
//   max-width: 95em; /*1520/16*/
//   padding: 0 20px;
//   margin: 0 auto;

//   @include atMedium {
//     padding: 0 30px;
//   }
//   @include atXlarge {
//     padding: 0 40px; //1440
//   }
// }
.wrapper {
  margin: 0 auto;
  max-width: 62.5rem;
  width: calc(100% - (var(--gutter) * 2));
}
.container {
  margin: 0 auto;
  max-width: 62.5rem;
}

p {
  word-break: break-word;
  word-wrap: break-word;
}
img {
  border: 0;
  height: auto;
  max-width: 100%;
}
a {
  color: $black;
  transition: all 0.3s ease-in-out;
}
