.nav-toggle-button {
  background-color: rgba($white, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  position: absolute;
  left: 15px;
  top: 15px;
  top: 18px;
  z-index: 10;
  border-radius: 100%;

  @include atMedium {
    display: none;
  }
}
.nav-toggle-button span {
  position: relative;
  height: 2px;
  width: 20px;
  background-color: rgba($black, 0.4);
}
.nav-toggle-button span:before,
.nav-toggle-button span:after {
  width: 100%;
  height: 2px;
  display: block;
  content: "";
  background-color: rgba($black, 0.4);
  position: absolute;
}
.nav-toggle-button span:before {
  top: -7px;
}
.nav-toggle-button span:after {
  top: 7px;
}
body.overflow-hidden .nav-toggle-button span {
  background-color: transparent !important;
}
body.overflow-hidden .nav-toggle-button span:before {
  transform: rotate(-45deg);
  top: 0;
}
body.overflow-hidden .nav-toggle-button span:after {
  transform: rotate(45deg);
  top: 0;
}

.bg-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
  transition: 0.2s all ease;
  opacity: 0;
  visibility: hidden;
}
.bg-overlay.active {
  z-index: 10;
  opacity: 1;
  visibility: visible;
}

// mobile nav

.mobile-navigation {
  background-color: $primary;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 20;
  height: 100%;
  max-width: 300px;
  width: 100%;
  overflow-y: scroll;
  left: -100%;
  transition: 0.3s all ease;

  @include at2Xlarge {
    left: -100% !important;
  }
}
.mobile-navigation.active {
  left: 0;
}
.mobile-navigation ul {
  display: flex;
  flex-direction: column;
}
.mobile-navigation ul li {
  font-size: 1.125rem;
  font-weight: 400;
  position: relative;
  border-bottom: 1px solid rgba($white, 0.2);
}
.mobile-navigation ul li.current_page_item a,
.mobile-navigation ul li.current-menu-item a {
  color: $white;
}
.mobile-navigation ul li a {
  padding: 10px 15px 10px 15px;
  min-height: 50px;
  display: block;
  color: $accent;
  text-decoration: none;
}
.mobile-navigation ul li.hasSubMenu > a {
  padding: 10px 50px 10px 15px;
}

// .mobile-navigation ul ul {
//   position: relative;
//   display: none;
//   width: 100%;
//   padding: 0;
//   margin: 0;
//   transition: opacity 0.5s ease;

//   li {
//     &:first-child {
//       border-top: 1px solid rgba($white, 0.2);
//     }

//     &:last-child {
//       border-bottom: 1px solid rgba($white, 0);
//     }
//   }

//   a {
//     position: relative;
//     padding-left: 45px;
//     background-color: rgba($black, 0.2);

//     &:before {
//       content: "";
//       width: 10px;
//       height: 1px;
//       background: $white;
//       display: block;
//       position: absolute;
//       top: 25px;
//       left: 20px;
//     }
//   }
// }

// .mobile-navigation
//   > ul
//   > li.menu-item-has-children
//   > ul.sub-menu
//   > li.menu-item-has-children
//   > a {
//   cursor: default;
//   // font-size: 1.125rem;
//   font-weight: 600;
//   text-transform: uppercase;
//   padding-left: 15px;
//   padding-top: 13px;

//   &:before {
//     display: none;
//   }
// }
// .mobile-navigation ul ul ul a {
//   background-color: rgba($black, 0.4);
// }

// .show-submenu {
//   position: absolute;
//   top: 0;
//   right: 0;
//   width: 50px;
//   height: 50px;
//   display: block !important;
//   background: rgba($white, 0.05);
//   cursor: pointer;
//   z-index: 1000;

//   &:before {
//     content: "☰";
//     color: $white;
//     font-size: 24px;
//     position: absolute;
//     line-height: 1;
//     top: 16px;
//     left: 16px;
//   }

//   @include at2Xlarge {
//     display: none !important;
//   }
// }
