.mamoni-content {
  &:not(:last-of-type) {
    margin-bottom: 50px;
  }
}
.mamoni-content__text {
  @include atMedium {
    text-align: justify;
  }

  &__heading {
    margin-bottom: 15px;
  }
}

.mamoni-content-two-col {
  display: grid;
  gap: 20px;

  @include atSmall {
    grid-template-columns: 1fr 1fr;
    gap: 30px;

    &--alt {
      @include atSmall {
        & > div:first-child {
          order: 2;
        }
        & > div:last-child {
          order: 1;
        }
      }
    }
  }
  @include atSmallAlt {
    grid-template-columns: max-content 1fr;
    gap: 50px;

    &--alt {
      grid-template-columns: 1fr max-content;
    }
  }
}
