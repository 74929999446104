.main-navigation-wrap {
  background-color: rgba($accent, 1);
  border-top: 2px double $primary;
  // border-bottom: 2px double $primary;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  display: none;

  @include atMedium {
    display: block;
  }
}
.main-navigation {
  display: flex;
  justify-content: space-between;
  font-weight: 700;

  li {
    position: relative;
    font-size: 1rem;
    font-family: $body-font;
    text-transform: uppercase;
    flex: auto;

    &.current-menu-item a,
    &.current_page_item a {
      // border-bottom: 4px solid $accent;
      background: $yellow-hoverbg;
      color: $white;
    }
    // &.menu-item-has-children a {
    //   padding-right: 20px;
    // }

    a {
      padding: 12px 15px 10px;
      text-decoration: none;
      color: $primary;
      // display: inline-block;
      display: block;
      font-weight: 700;

      &:hover {
        // background-color: rgba($white, 1);
        background: $yellow-hoverbg;
        color: $white;
      }
    }
  }
}
// .main-navigation > li.menu-item-has-children > ul.sub-menu {
//   width: 300px;
//   margin-left: -150px;
//   padding: 15px 15px 15px;
//   position: absolute;
//   // top: calc(100% + 4px);
//   top: calc(100% + 1px);
//   left: 50%;
//   background-color: rgba($accent, 1);
//   box-shadow: 0 0 20px rgba($white, 0.2);
//   opacity: 0;
//   transition: 0.3s;
//   visibility: hidden;
//   z-index: 5;
//   display: block;
//   // border-radius: 5px;

//   a {
//     padding: 8px 10px 6px;
//     color: $white;
//     display: block;
//     text-decoration: none;
//     font-size: 1rem;
//     font-weight: 500;

//     &:hover {
//       color: $primary;
//     }
//   }
// }
// .main-navigation
//   > li.menu-item-has-children
//   > ul.sub-menu
//   > li.menu-item-has-children
//   > a {
//   cursor: default;
//   font-size: 1.125rem;
//   font-weight: 600;
//   text-transform: uppercase;

//   &:hover {
//     background-color: $accent;
//     color: $white;
//   }
// }
// .main-navigation
//   > li.menu-item-has-children
//   > ul.sub-menu
//   > li.menu-item-has-children {
//   margin-top: 20px;

//   &:first-of-type {
//     margin-top: 0;
//   }
// }

// /*up arrow*/
// .main-navigation > li.menu-item-has-children > ul.sub-menu::before {
//   border-bottom: 6px solid $accent;
//   border-left: 8px solid transparent;
//   border-right: 8px solid transparent;
//   content: "";
//   // display: block;
//   position: absolute;
//   top: -6px;
//   left: 50%;
//   margin-left: -4px;
// }

// /* sub menu indicator arrow */
// .main-navigation > li.menu-item-has-children::after {
//   position: absolute;
//   right: 6px;
//   top: 45%;
//   border-left: 5px solid transparent;
//   border-right: 5px solid transparent;
//   border-top: 5px solid $primary;
//   content: "";
//   display: block;
//   margin: 0 -2px 0 0;
//   transition: transform 0.3s;
// }

// /* sub menu hover state */
// .main-navigation > li.menu-item-has-children:hover::after {
//   transform: rotate(180deg);
// }
// .main-navigation > li.menu-item-has-children:hover > ul.sub-menu,
// .main-navigation > li.menu-item-has-children > a:hover ul.sub-menu {
//   opacity: 1;
//   visibility: visible;
// }
