// .common-wrap {
//   margin-top: 30px;
//   color: $black;

//   @include atMedium {
//     margin-top: 50px;
//   }

//   &--white {
//     color: $white;
//   }
//   &--text-center {
//     text-align: center;
//   }

//   p {
//     font-size: 1rem;
//     // font-weight: 500;
//     margin-bottom: 15px;

//     // @include atSmallAlt {
//     //   font-size: 1.125rem;
//     // }
//     // @include atMedium {
//     //   font-size: 1.325rem;
//     // }
//     // @include atLarge {
//     //   font-size: 1.5rem;
//     // }

//     &:last-child {
//       margin-bottom: 0;
//     }
//   }

//   ol {
//     color: $black;
//     margin: 30px 0;
//     counter-reset: item;
//     @include atLarge {
//       padding: 0 15%;
//     }

//     li {
//       position: relative;
//       display: block;
//       font-weight: 600;
//       &:before {
//         content: counters(item, ".") ". ";
//         counter-increment: item;
//         margin-right: 10px;
//         font-weight: 600;
//       }
//     }

//     ol {
//       margin: 0;
//       padding: 10px 20px;

//       li {
//         padding: 5px 0 5px 35px;
//         font-weight: 400;

//         &:before {
//           position: absolute;
//           display: block;
//           left: 0;
//         }
//       }
//     }
//   }

//   ul {
//     color: $black;
//     margin: 30px 0;
//     list-style: none;
//     @include atLarge {
//       padding: 0 15%;
//     }

//     li {
//       position: relative;
//       padding-left: 20px;

//       &:before {
//         content: "";
//         width: 10px;
//         height: 10px;
//         background-color: $primary;
//         display: block;
//         position: absolute;
//         left: 0;
//         top: 10px;
//       }
//     }

//     ul {
//       margin: 0;
//     }
//   }
// }

// .common-button {
//   display: inline-block;
//   padding: 8px 15px;
//   background-color: $accent;
//   border: 1px solid $accent;
//   color: $white;
//   font-size: 1.125rem;
//   transition: all 0.3s ease;
//   text-decoration: none !important;
//   margin-top: 20px;

//   i {
//     margin-left: 10px;
//   }

//   &:hover {
//     color: $accent;
//     background-color: $black;
//     text-decoration: none;
//   }
// }

// .cookie-terms-page {
//   p,
//   ol,
//   ul {
//     color: $black;
//     @include atLarge {
//       padding: 0;
//     }
//   }
// }

.common-page-content {
  padding: 25px;
  border-radius: 10px;
  background-color: $accent;
  background: $gradient-light-yellow-to-yellow;

  @include atMedium {
    padding: 50px;
  }
}
