// // Google Fonts
// @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");

// Font Variables
// $body-font: "Open Sans", Helvetica, Arial, Sans-Serif;
$body-font: "PT Serif", Helvetica, Arial, serif;

// Colour Variables
$black: #000000;
$white: #ffffff;
$grey: #333333;
$light-yellow: #f1bb4e;
$yellow: #f9b81e;
$yellow-hoverbg: #c96909;
$light-orange: #f35c30;
$orange: #e55302;
$brown: #661700;

$primary: #661700;
$accent: #f9b81e;

$gradient-yellow-to-orange: linear-gradient(
  180deg,
  rgba(249, 184, 30, 1) 40%,
  rgba(229, 83, 2, 1) 100%
);
$gradient-light-yellow-to-yellow: linear-gradient(
  180deg,
  rgb(241, 187, 78, 1) 40%,
  rgba(249, 184, 30, 1) 100%
);

:root {
  --gutter: 15px;
}
// @include atSmallAlt {
//   :root {
//   }
// }
@include atMedium {
  :root {
    // --gutter: 30px;
    --gutter: 0;
  }
}
// @include atLarge {
//   :root {
//   }
// }
// @include atXlarge {
//   :root {
//     --gutter: 60px;
//   }
// }
