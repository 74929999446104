.photos-videos-content {
  &:not(:last-of-type) {
    margin-bottom: 50px;
  }

  &__heading {
    margin-bottom: 15px;
  }
  &__video-wrapper {
    position: relative;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  &__buttons {
    display: grid;
    gap: 15px;

    @include atSmall {
      grid-template-columns: repeat(3, minmax(max-content, 1fr));
    }

    .button {
      text-align: center;
      padding: 12px;
    }
  }
}
