.page-section {
  // padding: 50px 0;
  padding: 30px 0;

  @include atMedium {
    padding: 45px 0;
  }

  @include atLarge {
    padding: 60px 0;
  }

  &--bg-black {
    background-color: $black;
  }
  &--bg-grey {
    background-color: $grey;
  }
  &--bg-white {
    background-color: $white;
  }
  &--bg-primary {
    background-color: $primary;
  }
  &--bg-accent {
    background-color: $accent;
  }
  &--no-b-padding {
    padding-bottom: 0;
  }
  &--no-t-padding {
    padding-top: 0;
  }
  &--no-padding {
    padding: 0;
  }
  &--hidden {
    overflow: hidden;
  }
}

.page-section p,
.page-section ul {
  font-size: 1rem;
  // color: rgba($black, 1);
  color: rgba($primary, 1);
}
.page-section p {
  padding-bottom: 15px;

  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
.page-section p a {
  // color: rgba($primary, 1);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
