.site-header {
  background-color: $primary;

  .container {
    position: relative;
  }
}
.header-banner {
  background: url("../images/header/JOYGURU-BANNER-1.jpeg") 48% center no-repeat;
  background-size: cover;
  min-height: 443px;
}
.header-text {
  background: $accent;
  background: $gradient-light-yellow-to-yellow;
  padding: 30px 0;

  &__heading {
    text-transform: uppercase;

    // @include atXlarge {
    //   font-size: 1.625rem !important;
    // }

    span {
      display: block;

      @include atXSmall {
        display: inline-block;
      }
    }
  }
  &__guru-mantra {
    font-size: 0.875rem;
    margin-top: 15px;
    text-align: center;
    text-transform: uppercase;
    color: rgba($primary, 1);

    @include atSmall {
      font-size: 1rem;
    }
  }
}
// .main-nav-container {
//   border-bottom: 2px solid $accent;
// }
