// 2xl - 46 | 56 | 72 | 96
// xl - 36 | 46 | 56 | 72
// l - 32 | 42 | 52 | 60
// m - 26 | 36 | 44 | 48
// s - 22 | 24 | 30 | 36
// xs - 20 | 22 | 24 | 26
// xxs - 16 | 18 | 20 | 22

.headline {
  font-family: $body-font;

  &--text-black {
    color: $black;
  }
  &--text-white {
    color: $white;
  }
  &--text-primary {
    color: $primary;
  }
  &--text-accent {
    color: $accent;
  }

  &--light {
    font-weight: 300;
  }
  &--normal {
    font-weight: 400;
  }
  &--medium {
    font-weight: 500;
  }
  &--semi-bold {
    font-weight: 600;
  }
  &--bold {
    font-weight: 700;
  }
  &--black {
    font-weight: 900;
  }

  &--text-left {
    text-align: left;
  }
  &--text-center {
    text-align: center;
  }
  &--text-uppercase {
    text-transform: uppercase;
  }
}
.headline-2xl {
  font-size: 2.875rem;
  line-height: 1.1;

  @include atMediumAlt {
    font-size: 3.5rem;
  }
  @include atLarge {
    font-size: 4.5rem;
  }
  @include atXlarge {
    font-size: 6rem;
  }

  // font-size: clamp(2.875rem, 4.5rem, 6rem);
}
.headline-xl {
  font-size: 2.25rem;
  line-height: 1.1;

  @include atMediumAlt {
    font-size: 2.875rem;
  }
  @include atLarge {
    font-size: 3.5rem;
  }
  @include atXlarge {
    font-size: 4.5rem;
  }

  // font-size: clamp(2.25rem, 3.5rem, 4.5rem);
}
.headline-l {
  font-size: 2rem;
  line-height: 1.2;

  @include atMediumAlt {
    font-size: 2.625rem;
  }
  @include atLarge {
    font-size: 3.25rem;
  }
  @include atXlarge {
    font-size: 3.75rem;
  }

  // font-size: clamp(2rem, 3.25rem, 3.75rem);
}
.headline-m {
  font-size: 1.625rem;
  line-height: 1.2;

  @include atMediumAlt {
    font-size: 2.25rem;
  }
  @include atLarge {
    font-size: 2.75rem;
  }
  @include atXlarge {
    font-size: 3rem;
  }

  // font-size: clamp(1.625rem, 2.75rem, 3rem);
}
.headline-s {
  font-size: 1.375rem;
  line-height: 1.4;

  @include atMediumAlt {
    font-size: 1.5rem;
  }
  @include atLarge {
    font-size: 1.625rem;
  }
  @include atXlarge {
    font-size: 2.25rem;
  }

  // font-size: clamp(1.375rem, 2.875rem, 2.25rem);
}
.headline-xs {
  font-size: 1.25rem;
  line-height: 1.5;

  @include atMediumAlt {
    font-size: 1.375rem;
  }
  @include atLarge {
    font-size: 1.5rem;
  }
  @include atXlarge {
    font-size: 1.625rem;
  }

  // font-size: clamp(1.25rem, 2.875rem, 1.625rem);
}
.headline-xxs {
  font-size: 1rem;
  line-height: 1.5;

  @include atMediumAlt {
    font-size: 1.125rem;
  }
  @include atLarge {
    font-size: 1.25rem;
  }
  @include atXlarge {
    font-size: 1.375rem;
  }

  // font-size: clamp(1rem, 2.875rem, 1.375rem);
}

// Subline
.subline-text {
  font-size: 0.875rem;
  color: $black;
  font-weight: 700;
  padding: 8px 0 0 0;

  &--text-black {
    font-weight: 900;
  }
  &--white {
    color: $white;
  }
  &--center {
    text-align: center;
  }

  @include atSmallAlt {
    font-size: 1rem;
  }
  @include atMedium {
    font-size: 1.125rem;
  }
  @include atLarge {
    font-size: 1.25rem;
  }
}

.pre-heading {
  font-size: 0.8125rem; //13px
  color: rgba($grey, 0.5);
  margin-bottom: 10px;

  &--uppercase {
    text-transform: uppercase;
  }
  &--space-m {
    word-spacing: 5px;
  }
  &--space-s {
    word-spacing: 3px;
  }
}

// Page text
.text {
  font-size: 1rem;
}
