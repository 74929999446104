.button-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.button {
  display: inline-block;
  padding: 10px 20px 8px;
  font-size: 1rem;
  transition: all 0.4s ease;
  font-weight: 400;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
  &--text-uppercase {
    text-transform: uppercase;
  }
  &--pill {
    border-radius: 100px;
  }
  &--rounded-10 {
    border-radius: 10px;
  }

  &__icon {
    margin-left: 20px;

    svg {
      display: inline-block;
      vertical-align: middle;
      margin-top: -2px;
    }
  }
  &--grey {
    background-color: rgba($grey, 1);
    border: 1px solid rgba($grey, 1);
    color: rgba($primary, 1);

    &:hover {
      background-color: rgba($primary, 1);
      border: 1px solid rgba($primary, 1);
      color: rgba($white, 1);
    }
  }
  &--primary {
    background-color: rgba($primary, 1);
    border: 1px solid rgba($primary, 1);
    color: rgba($accent, 1);

    &:hover {
      background-color: rgba($primary, 1);
      border: 1px solid rgba($primary, 1);
      color: rgba($white, 1);
    }

    &--hover-white {
      &:hover {
        background-color: rgba($white, 1);
        border: 1px solid rgba($white, 1);
        color: rgba($primary, 1);
      }
    }
  }
  &--accent {
    background-color: rgba($accent, 1);
    border: 1px solid rgba($accent, 1);
    color: rgba($primary, 1);

    &:hover {
      background-color: rgba($primary, 1);
      border: 1px solid rgba($primary, 1);
      color: rgba($white, 1);
    }

    &--hover-white {
      &:hover {
        background-color: rgba($white, 1);
        border: 1px solid rgba($white, 1);
        color: rgba($primary, 1);
      }
    }
  }
  &--gradient {
    background-color: rgba($accent, 1);
    background: $gradient-yellow-to-orange;
    border: 1px solid rgba($accent, 1);
    color: rgba($black, 1);

    &:hover {
      background-color: rgba($primary, 1);
      border: 1px solid rgba($primary, 1);
      color: rgba($white, 1);
    }
  }
}
