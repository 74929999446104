.contact-content {
  display: grid;
  gap: 30px;

  grid-template-areas:
    "media"
    "address"
    "social-donation";

  @include atSmall {
    grid-template-areas:
      "address media"
      "address social-donation";
  }
  @include atSmallAlt {
    column-gap: 50px;
  }

  &__media {
    grid-area: media;
  }
  &__address {
    grid-area: address;
  }
  &__social-donation {
    grid-area: social-donation;
  }

  &__text {
    &:not(:first-child) {
      padding-top: 30px;
    }
    &:not(:last-child) {
      padding-bottom: 30px;
      border-bottom: 2px dotted $black;
    }
    &__heading {
      margin-bottom: 15px;
    }
    .tel {
      padding-bottom: 0;
      text-decoration: none !important;
      border-bottom: none !important;
    }

    a:not(.button) {
      color: $primary;
      text-decoration: none !important;
      padding-bottom: 2px;
      border-bottom: 1px solid $black;

      &:hover {
        border-bottom: 1px solid transparent;
      }
    }

    .button {
      text-decoration: none;
    }
  }
}
