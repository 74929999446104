/* footer */
.footer-navigation-1-wrap {
  // border-top: 2px dotted $light-orange;
  // border-bottom: 2px dotted $light-orange;
  // padding: 15px 0;
  border: 2px dotted $light-orange;
  padding: 15px;
  border-radius: 10px;
}
.footer-navigation-1 {
  display: grid;
  gap: 10px;

  @include atSmall {
    gap: 20px;
    grid-template-columns: repeat(2, minmax(max-content, 1fr));
  }
  @include atMedium {
    font-size: 1.125rem;
    grid-template-columns: repeat(4, minmax(min-content, max-content));
    justify-content: space-between;
  }

  li {
    position: relative;

    @include atMedium {
      &:not(:last-child)::after {
        content: "||";
        position: absolute;
        right: -50px;
        top: 0;
        color: $light-orange;
        display: flex;
      }
    }
  }

  a {
    color: $light-orange;
    text-decoration: none;

    &:hover {
      color: $white;
    }
  }
}

.footer-navigation-2 {
  display: grid;
  gap: 10px;
  padding: 30px 0;
  border-bottom: 1px solid rgba($light-orange, 0.3);

  @include atSmall {
    gap: 20px;
    grid-template-columns: repeat(2, minmax(max-content, 1fr));
  }
  @include atMedium {
    row-gap: 10px;
    grid-template-columns: repeat(4, minmax(max-content, 1fr));
  }

  a {
    color: rgba($light-orange, 0.6);
    text-decoration: none;

    &:hover {
      color: $white;
    }
  }
}

.copyright-credit {
  display: grid;
  gap: 20px;
  padding: 20px 0;
  color: rgba($light-orange, 0.5);

  @include atSmallAlt {
    grid-template-columns: max-content max-content;
    justify-content: space-between;
  }

  a {
    color: rgba($light-orange, 0.5);

    &:hover {
      text-decoration: none;
    }
  }
}
