.home-content {
  display: grid;
  gap: 30px;

  @include atSmallAlt {
    grid-template-columns: repeat(2, 1fr);

    &__media {
      order: 2;
    }
    &__text {
      order: 1;
    }
  }
}
.home-content__media {
  display: grid;
  gap: 20px;

  @include atSmallAlt {
    align-content: space-between;
  }

  &__images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    & > div img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
  &__button {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 15px;
  }
}
.home-content__text {
  padding: 25px;
  border-radius: 10px;
  background-color: $accent;
  background: $gradient-light-yellow-to-yellow;

  @include atMedium {
    padding: 50px;
    text-align: justify;
  }

  &__heading {
    padding-bottom: 15px;
  }

  a.button {
    text-decoration: none !important;
  }
}
